<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :dark="dark"
        size="sm"
      >
        <template #brand="{item}">
          <td>
            {{item.item.brand}}
          </td>
        </template>
        <template #particular="{item}">
          <td>
            {{item.item.particular}}
          </td>
        </template>
        <template #notifier="{item}">
          <td>
            <!-- {{item.notifier}}  -->
            <div style="display:flex;">
              <CInput
                  type="number"
                  size="sm"
                  style="width:50px;"
                  class="mb-0"
                  :value="item.notifier"
                  @blur="updateNotifier(item, $event.target.value)"
              />
              <!-- <CButton class="ml-1" color="info" size="sm"><CIcon size="sm" name="cil-pencil"/></CButton> -->
              <!-- <CButton class="ml-1" color="success" size="sm"><CIcon size="sm" name="cil-save"/></CButton>
              <CButton class="ml-1" color="danger" size="sm"><CIcon size="sm" name="cil-x"/></CButton> -->
            </div>
            
          </td>
        </template>
        <template #unit="{item}">
          <td>
            {{item.item.item_variations[0].unit.name}}
          </td>
        </template>
        <template #unit_cost="{item}">
          <td>
            ₱{{item.item.item_variations[0].franchise_cost.toLocaleString()}}
          </td>
        </template>
        <template #total_cost="{item}">
          <td>
            ₱{{totalCost(item)}}
          </td>
        </template>
        <template #onhand="{item}">
          <td>
            {{item.available_qty}}
          </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import moment from 'moment';
import RoleModal from "@/containers/RoleModal"

export default {
  name: 'Table',
  components: {
    RoleModal
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
            { key: 'brand', _style:'width:10%' },
            { key: 'particular', _style:'width:40%' },
            { key: 'notifier', _style:'width:10%' },
            { key: 'unit', _style:'width:10%' },
            { key: 'onhand', _style:'width:10%' },
            { key: 'unit_cost', _style:'width:10%' },
            { key: 'total_cost', _style:'width:10%' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  filters: {
      formatChange(data){
          return moment(String(data)).format('Y-MM-D');
      },
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    updateNotifier(value, event){
      const params = {
        id: value.id,
        notifier: parseInt(event)
      }
      this.$store.dispatch('operation_inventory/updateNotifier', params);
      
    },
    totalCost(value){
      var total_cost = value.item.item_variations[0].franchise_cost * value.available_qty;
      return total_cost.toLocaleString()
    },
    getValue(data){
      this.$emit('event_child', data, 'edit');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
    getValueCar(data){
      this.$emit('event_child', data, 'car');
    },
  }
}
</script>
